import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";

const PPIs = () => {
	return (
		<Blog title="Non Coeliac Gluten Sensitivity: Real or Imagined?">
			<SEO
				title="Non Coeliac Gluten Sensitivity: Real or Imagined?"
				keywords={[
					`Coeliac`,
					`Gluten-free evidence`,
					`Gluten sensitivity hype`,
					`Wheat allergy`,
					`Foods to avoid for wheat allergy`,
				]}
				type="article"
				description="The relationship between dementia and proton pump inhibitors (PPIs)"
			/>
			<Par text="Our understanding of the increased worldwide demand for gluten and wheat free products has not, as yet, been supported by good scientific evidence. Aside from well-defined coeliac disease, gluten is blamed as a trigger of symptoms by up to 40% of adults who self report food hypersensitivity. There is a thin line between coeliac disease, wheat sensitivity, irritable bowel syndrome (IBS) and non-coeliac gluten sensitivity making it difficult to exactly differentiate between these disorders." />
			<Par text="Coeliac disease occurs when genetically susceptible patients are exposed to dietary gluten, activating a specific immune response. This condition affects up to 1.4% of the Australian population and leads to small bowel damage and elevated coeliac specific antibodies. The only treatment at present is a strict lifelong gluten free diet (GFD). The classical GI symptoms are those of malabsorption ie diarrhoea, abdominal pain, bloating, wind and distension but also general symptoms including fatigue. Investigations reveal iron deficiency with or without anaemia and osteoporosis as well as positive antibodies to tissue transglutaminase. Now also, asymptomatic presentation is accepted as common." />
			<Par text="Wheat allergy is an IgE mediated reaction to the insoluble gliadins of wheat. The symptoms of wheat allergy develop within minutes to hours of gluten and include itching and swelling in the mouth, nose, eyes and throat, skin rash and wheezing and occasionally life threatening anaphylaxis." />
			<Par text="IBS is a disorder  characterised by GI symptoms ( abdominal pain and altered bowels) with no abnormal pathology and affects up to 15% of the population. IBS presents a major challenge because of multiple contributing factors and conflicting evidence for management strategies but up to 75% of patients respond to a low FODMAP diet." />
			<Par text="Non coeliac gluten sensitivity (NCGS) is a condition where intestinal and extra intestinal symptoms are triggered by gluten ingestion in the absence of coeliac disease and wheat allergy. The clinical picture of NCGS is a combination of IBS-like symptoms, behaviour disturbances and systemic manifestations. The systemic manifestations including tiredness, headache, fibromyalgia-like joint and muscle pain, leg or arm numbness, “foggy mind”, dermatitis or skin rash, depression and anxiety may be common. Symptoms occur within a within hours or days of gluten ingestion and may improve within a few days of excluding gluten." />
			<Par text="Despite the first reports of this condition dating back to 1978,  much remains unknown about this condition and the results of clinical trials have been inconsistent. As yet no biomarker has been discovered. At this stage without convincing, reproducible results from clinical trials showing effects on inflammatory or immune markers, NCGS can be regarded as a subgroup of IBS and distinct from coeliac disease. Lowering the dietary intake of FODMAPs remains the first line therapy for patients experiencing GI symptoms. A gluten free diet is also very low in oligosaccharides and the clinical response may result from a reduction in these rather than gluten." />
			<Par text="For children with symptoms that they attribute to gluten, it is important to test for both coeliac disease and  IgE mediated wheat allergy. Testing should be performed before eliminating gluten from the diet to avoid false negative testing on a GFD. Ideally patients need to consume 4 slices of wheat based bread (or equivalent) for 6 weeks before testing in adults and this amount can be halved in children." />
		</Blog>
	);
};

export default PPIs;
